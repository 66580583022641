import './job-slider.scss';
import Swiper from 'swiper/bundle';
import Follower from '../../atoms/sliderfollow';

class JobSlider {
    constructor($element) {
        this.$jobSliderRoot = $element;
        this.$jobSlider = this.$jobSliderRoot.querySelector('.swiper');

        this.$slides = this.$jobSliderRoot.querySelectorAll('.swiper-slide');
        this.slider = [];
    }

    initialize() {
        if (this.$slides.length > 0) {
            this.initSlider(this.$slides.length);
            this.setEvents();
        }
    }

    initSlider() {
        const sliderOptions = {
            loop: false,
            centeredSlides: true,
            autoplay: false,
            initialSlide: 0,
            slidesPerView: 'auto',
            initAttr: 'data-job-slider',
            preventClicks: true,
            preventClicksPropagation: true,
            responsive: true,
            pagination: {
                el: '.job-slider__swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.2,
                    spaceBetween: 16
                },
                600: {
                    slidesPerView: 2,
                    spaceBetween: 16
                },
                768: {
                    slidesPerView: 'auto',
                    spaceBetween: 64,
                    initialSlide: 0
                }
            }
        };

        this.slider = new Swiper(this.$jobSlider, sliderOptions);
    }

    setEvents() {
        this.$follower = new Follower(this.$jobSlider, (direction) => {
            if (direction === 'left') {
                this.slider.slidePrev();
            } else {
                this.slider.slideNext();
            }
        });

        const el = document.getElementsByClassName('job-preview__slide');

        for (var i = 0; i < el.length; i++) {
            el[i].addEventListener('keypress', (e) => {
                if (e.which === 13 || e.which === 32) {
                    e.preventDefault();
                    e.target.click();
                }
            });
        }
    }
}

export { JobSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $jobSliders = $context.querySelectorAll(
            '[data-job-slider="root"]'
        );

        if ($jobSliders.length > 0) {
            for (let i = 0; i < $jobSliders.length; i++) {
                const $jobSlider = new JobSlider($jobSliders[i]);
                $jobSlider.initialize();
            }
        }
    }
});
